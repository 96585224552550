import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Events from './components/Events';
import Internships from './components/Internships';
import LearningJourneys from './components/LearningJourneys';
import Townhalls from './components/Townhalls';
import TownhallDetails from './components/TownhallDetails';
import Staff from './components/Staff';
import Mission from './components/Mission';
import Contact from './components/Contact';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
  return (
    <Router>
      <CssBaseline />
      <Header />
      <div className="flex flex-col min-h-screen">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/events" element={<Events />} />
          <Route path="/internships" element={<Internships />} />
          <Route path="/learning-journeys" element={<LearningJourneys />} />
          <Route path="/townhalls" element={<Townhalls />} />
          <Route path="/townhall/:id" element={<TownhallDetails />} />
          <Route path="/about/staff" element={<Staff />} />
          <Route path="/about/mission" element={<Mission />} />
          <Route path="/about/contact" element={<Contact />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
