import React, { useState } from 'react';
import { AppBar, Toolbar, Button, Menu, MenuItem, Container, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../assets/homepage/logo.png';

function Header() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" sx={{ borderRadius: 2, mb: 2 }}>
      <Container>
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Link to="/" className="logo-link">
              <img src={logo} alt="Logo" style={{ height: '40px', marginRight: '16px' }} />
            </Link>
            <Button color="inherit" component={Link} to="/events">Events</Button>
            <Button color="inherit" component={Link} to="/internships">Internships</Button>
            <Button color="inherit" component={Link} to="/learning-journeys">Learning Journeys</Button>
            <Button color="inherit" component={Link} to="/townhalls">Townhalls</Button>
            <Button color="inherit" onClick={handleClick}>About</Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem component={Link} to="/about/mission" onClick={handleClose}>Mission</MenuItem>
              <MenuItem component={Link} to="/about/contact" onClick={handleClose}>Contact</MenuItem>
              <MenuItem component={Link} to="/about/staff" onClick={handleClose}>Staff</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
