import React from 'react';
import { Container, Box, Typography, Grid, Card, CardContent, CardMedia } from '@mui/material';
import './styles/Staff.css';

function Staff() {
    return (
        <Container>
            <Box sx={{ textAlign: 'center', my: 4 }}>
                <Typography variant="h2" gutterBottom>
                    Who we are
                </Typography>
            </Box>
            <Grid container spacing={4}>
                {/* Member 1 */}
                <Grid item xs={12} sm={6}>
                    <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
                        <CardMedia
                            component="img"
                            image="/placeholderpfp.png"
                            alt="Founder"
                            sx={{ width: 160, height: 160, borderRadius: '50%' }}
                        />
                        <CardContent sx={{ textAlign: 'center' }}>
                            <Typography variant="h5" component="div">
                                Low Hao Han
                            </Typography>
                            <Typography variant="h6" color="text.secondary">
                                Founder/CEO
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Incoming UNSW undergraduate, avid hackathon challenger.
                            </Typography>
                            <Typography variant="body1" component="div">
                                <strong>Notable Works:</strong>
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Cloudhacks - DDoS mitigation as an Optimization Problem (Best Use of AI)
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Govtech Geekout 2024 - Glassdoor for Students (2nd Place)
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                DSTA BrainHack 2024 - Today-I-Learned AI (TIL-AI) (Semi-Finalist)
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                OneService Game Changers Challenge - Gamification of Recycling (Top 5)
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                HackOMania - StressPulse: A Dynamic Stress Meter (Top 7)
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                {/* Member 2 */}
                <Grid item xs={12} sm={6}>
                    <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
                        <CardMedia
                            component="img"
                            image="/cat.png"
                            alt="Co-founder"
                            sx={{ width: 160, height: 160, borderRadius: '50%' }}
                        />
                        <CardContent sx={{ textAlign: 'center' }}>
                            <Typography variant="h5" component="div">
                                Cao Ziyi
                            </Typography>
                            <Typography variant="h6" color="text.secondary">
                                Co-Founder and Head of Business Development
                            </Typography>
                            <Typography variant="body1" paragraph>
                                NUS Global Merit Scholar
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Incoming NUS Undergraduate in Business and Economics
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Staff;
