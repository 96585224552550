import React from 'react';

function Mission() {
    return (
        <div className="mission-section">
          <h1>About Us</h1>
          <h2>Mission</h2>
          <p>TechLeads SG aim to empower students with tech skills, awareness and education, provide them with industrial attachments and foster community engagement through tech.</p>  
        </div>
    );
}
export default Mission;