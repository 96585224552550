import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box, CardMedia, CardContent, Card, Button } from '@mui/material';
import SignUpForm from './SignUpForm';
import townhallData from '../assets/townhallData';
import './styles/Home.css';

function TownhallDetails() {
  const { id } = useParams();
  const townhall = townhallData[id];
  const [showForm, setShowForm] = useState(false);

  if (!townhall) {
    return <Typography variant="h5">Townhall not found</Typography>;
  }

  const handleSignUpClick = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  return (
    <Container className="max-w-lg mx-auto my-4 p-4 bg-white rounded shadow">
      <Typography variant="h5" component="h1" className="text-center text-xl font-bold mb-4">
        {townhall.title}
      </Typography>
      <Card className="mb-3 shadow-md">
        <CardMedia
          component="img"
          alt={townhall.title}
          className="w-full h-auto object-cover"
          image={townhall.image}
          style={{ maxHeight: '300px' }}
        />
        <CardContent>
          <Typography variant="body1" paragraph className="text-center text-base">
            {townhall.description}
          </Typography>
          {townhall.signUpForm && (
            <Box mt={2} className="flex justify-center">
              <Button variant="contained" color="primary" onClick={handleSignUpClick}>Sign Up</Button>
            </Box>
          )}
        </CardContent>
      </Card>
      <Box>
        <Typography variant="h6" component="h2" className="text-lg font-semibold mb-2">
          Content
        </Typography>
        <Typography variant="body1" paragraph className="text-sm leading-relaxed" dangerouslySetInnerHTML={{ __html: townhall.content }} />
      </Box>
      {showForm && <SignUpForm onClose={handleCloseForm} eventTitle={townhall.title} />}
      {townhall.refreshments && (
        <Box mt={3}>
          <Typography variant="h6" component="h2" className="text-lg font-semibold mb-2">
            Light Refreshments
          </Typography>
          <Typography variant="body1" paragraph className="text-sm leading-relaxed">
            Light refreshments like drinks and snacks will be provided.
          </Typography>
        </Box>
      )}
    </Container>
  );
}

export default TownhallDetails;
