import React, { useState } from 'react';
import './styles/Contact.css';
import illustration from '../assets/contact-illustration.jpg';

function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [isLoading, setIsLoading] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [isError, setIsError] = useState(false); // State to manage error styling

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setFeedbackMessage('');
        setIsError(false);

        const formDataToSend = {
            access_key: '55f7176c-4570-4235-8498-eb90cdd70d7c',
            name: formData.name,
            email: formData.email,
            message: formData.message
        };

        try {
            const response = await fetch('https://api.web3forms.com/submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formDataToSend)
            });

            if (response.ok) {
                setFeedbackMessage('Email sent successfully');
                setFormData({ name: '', email: '', message: '' });
            } else {
                setFeedbackMessage('Failed to send email: ');
                setIsError(true);
            }
        } catch (error) {
            console.error('Error sending email:', error);
            setFeedbackMessage('Error sending email');
            setIsError(true);
        }

        setIsLoading(false);
    };

    return (
        <div className="contact-section">
            <h1>Contact Us</h1>
            <div className="contact-content">
                <form className="contact-form" onSubmit={handleSubmit}>
                    <div className="input-container">
                        <label htmlFor="name">Name</label>
                        <div className="input-icon">
                            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
                            <i className="fa fa-user"></i>
                        </div>
                    </div>

                    <div className="input-container">
                        <label htmlFor="email">Email</label>
                        <div className="input-icon">
                            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
                            <i className="fa fa-envelope"></i>
                        </div>
                    </div>

                    <div className="input-container">
                        <label htmlFor="message">Message</label>
                        <div className="input-icon">
                            <textarea 
                                id="message" 
                                name="message" 
                                value={formData.message} 
                                onChange={handleChange} 
                                required
                            ></textarea>
                            <i className="fas fa-comment"></i>
                        </div>
                    </div>

                    <button type="submit" disabled={isLoading} className="submit-button">
                        {isLoading ? 'Sending...' : 'Send'}
                    </button>
                    {feedbackMessage && <div className={`feedback-message ${isError ? 'error' : 'success'}`}>{feedbackMessage}</div>}
                </form>
                <div className="contact-illustration">
                    <img src={illustration} alt="Contact Us" />
                </div>
            </div>
        </div>
    );
}

export default Contact;
