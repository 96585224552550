import React from 'react';

function Events() {
  return (
    <main>
      <h1>Events</h1>
      <p>Information about upcoming and past events.</p>
    </main>
  );
}

export default Events;
