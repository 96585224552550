import React from 'react';
import { Container, Grid, Card, CardContent, CardMedia, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import townhallImage1 from '../assets/townhallpage/townhall1.jpg';
import townhallImage2 from '../assets/townhallpage/townhall2.jpg';
import townhallImage3 from '../assets/townhallpage/townhall3.jpg';
import './styles/Home.css'; // Import the Home.css file

const upcomingTownhalls = [
  {
    title: "Interesting developments in AI from the past 3 months",
    description: "Online",
    image: townhallImage1,
    link: "/townhall/ai-developments"
  },
  {
    title: "Tech job trends in the past 3 months",
    description: "Online",
    image: townhallImage2,
    link: "/townhall/job-trends"
  }
];

const pastTownhalls = [
  {
    title: "Importance of a mentor in your tech journey",
    description: "Venue: Our Tampines Hub",
    image: townhallImage3,
    link: "/townhall/tech-mentor"
  },
  {
    title: "Breakout activities",
    description: "Venue: Botanic Gardens",
    image: townhallImage1,
    link: "/townhall/breakout-activities"
  }
];

function Townhalls() {
  return (
    <Container>
      <Typography variant="h2" gutterBottom>
        Townhalls
      </Typography>

      <Box mb={4}>
        <Typography variant="h4" gutterBottom>
          Upcoming Townhalls
        </Typography>
        <Grid container spacing={3}>
          {upcomingTownhalls.map((townhall, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Link to={townhall.link} className="card-link">
                <Card className="clickable-card townhall-card">
                  <CardMedia
                    component="img"
                    height="140"
                    image={townhall.image}
                    alt={townhall.title}
                    className="card-image"
                  />
                  <CardContent className="card-content">
                    <Typography variant="h6">
                      {townhall.title}
                    </Typography>
                    <Typography variant="body2">
                      {townhall.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box>
        <Typography variant="h4" gutterBottom>
          Past Townhalls
        </Typography>
        <Grid container spacing={3}>
          {pastTownhalls.map((townhall, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Link to={townhall.link} className="card-link">
                <Card className="clickable-card townhall-card">
                  <CardMedia
                    component="img"
                    height="140"
                    image={townhall.image}
                    alt={townhall.title}
                    className="card-image"
                  />
                  <CardContent className="card-content">
                    <Typography variant="h6">
                      {townhall.title}
                    </Typography>
                    <Typography variant="body2">
                      {townhall.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
}

export default Townhalls;