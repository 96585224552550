import React, { useState, useEffect } from 'react';
import './styles/Contact.css';
import './styles/SignUpForm.css';

function SignUpForm({ onClose, eventTitle }) {
    const [formData, setFormData] = useState({
        event: eventTitle || '',
        name: '',
        email: ''
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [isError, setIsError] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setFeedbackMessage('');
        setIsError(false);

        const formDataToSend = {
            access_key: '55f7176c-4570-4235-8498-eb90cdd70d7c',
            event: formData.event,
            name: formData.name,
            email: formData.email
        };

        try {
            const response = await fetch('https://api.web3forms.com/submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formDataToSend)
            });

            if (response.ok) {
                setIsSubmitted(true);
                setTimeout(() => {
                    onClose();
                }, 3000);
            } else {
                const errorData = await response.json();
                setFeedbackMessage('Failed to send email: ' + errorData.error);
                setIsError(true);
            }
        } catch (error) {
            console.error('Error sending email:', error);
            setFeedbackMessage('Error sending email');
            setIsError(true);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        if (isSubmitted) {
            const timer = setTimeout(() => {
                onClose();
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [isSubmitted, onClose]);

    return (
        <div className="signup-form-overlay" onClick={onClose}>
            <div className="signup-form-container" onClick={(e) => e.stopPropagation()}>
                {!isSubmitted ? (
                    <form className="contact-form" onSubmit={handleSubmit}>
                        <div className="input-container">
                            <label htmlFor="event">Event</label>
                            <div className="input-icon">
                                <textarea 
                                    id="event" 
                                    name="event" 
                                    value={formData.event} 
                                    readOnly
                                    rows={2}
                                    style={{resize: 'none'}}
                                />
                                <i className="fa fa-calendar"></i>
                            </div>
                        </div>

                        <div className="input-container">
                            <label htmlFor="name">Name</label>
                            <div className="input-icon">
                                <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
                                <i className="fa fa-user"></i>
                            </div>
                        </div>

                        <div className="input-container">
                            <label htmlFor="email">Email</label>
                            <div className="input-icon">
                                <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
                                <i className="fa fa-envelope"></i>
                            </div>
                        </div>

                        <button type="submit" disabled={isLoading} className="submit-button">
                            {isLoading ? <div className="spinner-icon"></div> : 'Sign Up'}
                        </button>
                        {feedbackMessage && <div className={`feedback-message ${isError ? 'error' : 'success'}`}>{feedbackMessage}</div>}
                    </form>
                ) : (
                    <div className="thank-you-message fade-out">
                        <i className="fa fa-check"></i>
                        <p>Thank you for registering!</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default SignUpForm;