import React from 'react';

function Internships() {
  return (
    <main>
      <h1>Internships</h1>
      <p>Details about internship opportunities and application process.</p>
    </main>
  );
}

export default Internships;
