import React from 'react';

function LearningJourneys() {
  return (
    <main>
      <h1>Learning Journeys</h1>
      <p>Explore our learning journeys and see how tech works in the real world.</p>
    </main>
  );
}

export default LearningJourneys;
