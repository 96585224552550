import React, { useState, useEffect } from 'react';
import { Box, Typography, Container, Grid, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../assets/homepage/logo.png';
import aboutnetwork from '../assets/homepage/aboutnetwork.png';
import abouttownhall from '../assets/homepage/abouttownhall.png';
import abouttalks from '../assets/homepage/abouttalks.png';
import animeGirl from '../assets/homepage/anime-girl.gif';
import './styles/Home.css';

function Home() {
    const testimonials = [
        {
            text: "TechLeads SG has been instrumental in helping me find the best tech events and internships. Their community is supportive and always willing to help.",
            author: "Member 1"
        },
        {
            text: "I have gained so much knowledge and made valuable connections through TechLeads SG. It's an amazing platform for anyone in the tech industry.",
            author: "Member 2"
        },
        {
            text: "placeholder statement",
            author: "Member 3"
        }
    ];

    const [currentTestimonial, setCurrentTestimonial] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTestimonial((prevTestimonial) => (prevTestimonial + 1) % testimonials.length);
        }, 5000);
        return () => clearInterval(interval);
    }, [testimonials.length]);

    return (
        <Container>
            {/* Header */}
            <Box sx={{ textAlign: 'center', my: 6 }}>
                <img src={logo} alt="TechLeads SG Logo" style={{ width: '150px', margin: 'auto' }} />
                <Typography variant="h2" component="h1" gutterBottom>
                    Welcome to TechLeads SG
                </Typography>
            </Box>

            {/* Intro */}
            <Box sx={{ textAlign: 'center', my: 6 }}>
                <Typography variant="h4" gutterBottom>
                    Who We Are
                </Typography>
                <Typography variant="body1" paragraph sx={{ maxWidth: '800px', mx: 'auto', mb: 4 }}>
                    TechLeads SG is a social enterprise dedicated to connecting technology enthusiasts with events, internships, and learning opportunities. Our mission is to empower individuals by providing access to the best resources and networks in the tech industry.
                </Typography>
                <Button variant="contained" color="primary" href="/about">
                    Learn More About Us
                </Button>
            </Box>

            {/* Featured events */}
            <Box sx={{ textAlign: 'center', my: 6 }}>
                <Typography variant="h4" gutterBottom>
                    Featured
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Link to="/townhalls" className="card-link">
                            <Box sx={{ height: '100%' }} className="clickable-card">
                                <img src={abouttownhall} alt="Townhalls" className="card-image" />
                                <div className="card-content">
                                    <Typography gutterBottom variant="h5" component="div">
                                        Tech Huddle
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Join us for our upcoming town hall where we come together to understand about the tech scene in Singapore.
                                    </Typography>
                                </div>
                            </Box>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Link to="/events" className="card-link">
                            <Box sx={{ height: '100%' }} className="clickable-card">
                                <img src={abouttalks} alt="Talks" className="card-image" />
                                <div className="card-content">
                                    <Typography gutterBottom variant="h5" component="div">
                                        Empowerment through Tech
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Participate in our upcoming talk to know how you can use tech to enhance your quality of life.
                                    </Typography>
                                </div>
                            </Box>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Link to="/events" className="card-link">
                            <Box sx={{ height: '100%' }} className="clickable-card">
                                <img src={aboutnetwork} alt="Networking" className="card-image" />
                                <div className="card-content">
                                    <Typography gutterBottom variant="h5" component="div">
                                        Meet and Greet
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Network with tech professionals at our exclusive meetup event.
                                    </Typography>
                                </div>
                            </Box>
                        </Link>
                    </Grid>
                </Grid>
            </Box>

            {/* Testimonials Section */}
            <Box sx={{ textAlign: 'center', my: 6, position: 'relative' }}>
                <Typography variant="h4" gutterBottom>
                    What Our Members Say
                </Typography>
                <Box className="testimonial-section">
                    <img src={animeGirl} alt="Girl speaking" className="speaking-girl" />
                    <Box className="testimonial-box">
                        <Typography variant="body1" paragraph>
                            {testimonials[currentTestimonial].text}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" align="right">
                            - {testimonials[currentTestimonial].author}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            {/* Footer */}
            <Box sx={{ bgcolor: 'background.paper', p: 2, mt: 4, textAlign: 'center' }}>
                <Typography variant="body2">© 2024 TechLeads SG. All rights reserved.</Typography>
            </Box>
        </Container>
    );
}

export default Home;
