import townhallImage1 from '../assets/townhallpage/townhall1.jpg';
import townhallImage2 from '../assets/townhallpage/townhall2.jpg';
import townhallImage3 from '../assets/townhallpage/townhall3.jpg';

const townhallData = {
  "ai-developments": {
    title: "Interesting developments in AI from the past 3 months",
    description: "An analysis on the trends in tech in the past 3 months.",
    image: townhallImage1,
    content: `
      <ul>
        <li>
          <strong>New Algorithmic Advances</strong>: Researchers at MIT have developed a new reinforcement learning algorithm that significantly reduces training time while improving accuracy. This algorithm is poised to enhance AI applications in robotics and autonomous systems.
        </li>
        <li>
          <strong>Breakthroughs in Natural Language Processing (NLP)</strong>: OpenAI released an updated version of GPT, which demonstrates an improved understanding of context and generates more coherent and contextually relevant responses. This development has significant implications for AI-driven customer service and content generation.
        </li>
        <li>
          <strong>AI in Healthcare</strong>: A collaborative effort between Stanford University and Google Health resulted in an AI model capable of detecting early signs of diabetic retinopathy with unprecedented accuracy. This breakthrough is expected to revolutionize early diagnosis and treatment in ophthalmology.
        </li>
        <li>
          <strong>Ethical AI and Fairness</strong>: The University of Toronto introduced a new framework for evaluating and mitigating bias in AI systems. This framework provides a comprehensive approach to ensuring AI fairness, particularly in sensitive applications like hiring and law enforcement.
        </li>
        <li>
          <strong>AI for Environmental Sustainability</strong>: A group of scientists at the University of Cambridge developed an AI-based solution to optimize energy consumption in smart grids. Their approach has demonstrated a potential reduction in energy waste by up to 30%, contributing to more sustainable energy management.
        </li>
      </ul>
    `,
    signUpForm: true,
    refreshments: false,
  },
  "job-trends": {
    title: "Tech job trends in the past 3 months",
    description: "Explore the latest trends in the tech job market, including in-demand skills, job roles, and hiring patterns.",
    image: townhallImage2,
    content: "Detailed content about tech job trends...",
    signUpForm: true,
    refreshments: false,
  },
  "tech-mentor": {
    title: "Importance of a mentor in your tech journey",
    description: "Discussing the pivotal role mentors play in shaping successful tech careers, with insights from industry leaders.",
    image: townhallImage3,
    content: "Detailed content about the importance of mentors...",
    signUpForm: false,
    refreshments: true,
  },
  "breakout-activities": {
    title: "Breakout activities",
    description: "Engage in interactive breakout sessions designed to foster creativity and collaboration among tech enthusiasts.",
    image: townhallImage1,
    content: "Detailed content about breakout activities...",
    signUpForm: false,
    refreshments: true,
  }
};

export default townhallData;
