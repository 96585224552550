import React from 'react';
import { Box, Typography } from '@mui/material';

function Footer() {
  return (
    <Box sx={{ bgcolor: 'background.paper', p: 2, position: 'fixed', bottom: 0, width: '100%', textAlign: 'center' }}>
      <Typography variant="body2">© 2024 TechLeads SG. All rights reserved.</Typography>
    </Box>
  );
}

export default Footer;
